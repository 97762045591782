import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import "./styles/LoginModal.css";
import { getAppLang } from "../redux/selectors.js";
import LoginModalField from "../components/LoginModalField.jsx";
import logo from "../images/logo.png";
import { userLoginThunk } from "../redux/thunks.js";

const LoginModal = ({ closeModal, lang, modalRef, userLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      //console.log("clicked");
      closeModal();
    }
  };

  const login = async () => {
    //console.log(`Logging in with email: ${email} and password: ${password}`);
    await userLogin(email, password);
    setEmail("");
    setPassword("");
    closeModal();
  };

  return (
    <div
      className="login-modal-backdrop"
      onClick={handleBackdropClick}
      ref={modalRef}
    >
      <div className="login-modal">
        <div className="login-modal-logo">
          <img className="modal-logo" src={logo} alt="Logo" />
        </div>
        <div className="login-modal-header">
          {lang === "en" ? "Login" : "Connexion"}
        </div>
        <div className="login-modal-body">
          <LoginModalField
            label={lang === "en" ? "Email" : "Courriel"}
            stateVal={email}
            stateFunc={setEmail}
          />
          <LoginModalField
            label={lang === "en" ? "Password" : "Mot de passe"}
            stateVal={password}
            stateFunc={setPassword}
            passwordField
          />
        </div>
        <div className="login-modal-footer">
          <div className="login-modal-button" onClick={login} onTouchEnd={login}>
            {
              lang === "fr" &&
              "Connexion"
            }
            {
              lang === "en" &&
              "Login"
            }
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: getAppLang(state)
});

const mapDispatchToProps = (dispatch) => ({
  userLogin: async (email, password) => dispatch(userLoginThunk(email, password))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);