import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import "./styles/AdminModal.css";
import { getAppLang } from "../redux/selectors.js";

const AdminModal = ({ closeModal, lang, modalRef }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      //console.log("clicked");
      closeModal();
    }
  };

  return (
    <div
      className="admin-modal-backdrop"
      onClick={handleBackdropClick}
      ref={modalRef}
    >
      <div className="admin-modal">
        <h2>Service Modal</h2>
        <p>Content goes here...</p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: getAppLang(state)
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AdminModal);