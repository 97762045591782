import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import "./styles/ServiceModal.css";
import { getAppLang } from "../redux/selectors.js";
import CourseService from "../services/CourseService.jsx";
import IbService from "../services/IbService.jsx";
import OrientationService from "../services/OrientationService.jsx";
import SupportService from "../services/SupportService.jsx";

const ServiceModal = ({ activeService, closeModal, modalRef, register }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      //console.log("clicked");
      closeModal();
    }
  };

  return (
    <div
      className="services-modal-backdrop"
      onClick={handleBackdropClick}
      ref={modalRef}
    >
      <div className="services-modal">
        <div style={{
          display: activeService === "ibService" ? "block" : "none"
        }}>
          <IbService register={register} />
        </div>
        <div style={{
          display: activeService === "supportService" ? "block" : "none"
        }}>
          <SupportService register={register} />
        </div>
        <div style={{
          display: activeService === "courseService" ? "block" : "none"
        }}>
          <CourseService register={register} />
        </div>
        <div style={{
          display: activeService === "orientationService" ? "block" : "none"
        }}>
          <OrientationService register={register} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: getAppLang(state)
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceModal);