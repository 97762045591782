import { TextField } from "@mui/material";
import { withStyles } from "@mui/styles";
import React from 'react';
import { connect } from 'react-redux';

const CssTextField = withStyles({
  root: {
    "& label": {
      color: "#334155",
      "&.Mui-focused": {
        color: "#0f172a"
      }
    },
    "&:hover label": {
      color: "#0f172a"
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#0f172a"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0f172a"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#334155",
      },
      "&:hover fieldset": {
        borderColor: "#0f172a"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0f172a"
      }
    },
    "& .MuiOutlinedInput-input": {
      color: "#334155"
    }
  }
})(TextField);

const LoginModalField = ({label, passwordField, stateVal, stateFunc}) => {
  return (
    <CssTextField
      label={label}
      variant="standard"
      value={stateVal}
      onChange={(e) => stateFunc(e.target.value)}
      type={passwordField ? "password" : "text"}
      margin="normal"
      fullWidth
    />
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LoginModalField);