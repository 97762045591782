import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import "./styles/AccountModal.css";
import { getAppLang } from "../redux/selectors.js";

const AccountModal = ({
  closeModal,
  lang,
  login,
  modalRef,
  register,
}) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      //console.log("clicked");
      closeModal();
    }
  };

  return (
    <div
      className="account-modal-backdrop"
      onClick={handleBackdropClick}
      ref={modalRef}
    >
      <div className="account-modal">
        <div className="account-register-button" onClick={register}>
          {lang === "en" ? "Register" : "Inscription"}
        </div>
        <div className="account-login-button" onClick={login}>
          {lang === "en" ? "Login" : "Connexion"}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: getAppLang(state)
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AccountModal);