import React, { useEffect, useState } from 'react';
import { getAppLang } from "../redux/selectors.js";
import { connect } from 'react-redux';
import "./styles/Landing.css";
import logo from "../images/logo.png";

import data from "../data/appData.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const imageUrls = [
  require("../images/acceuil1.png"),
  require("../images/acceuil2.png"),
  require("../images/acceuil3.png"),
  require("../images/acceuil4.png"),
];

const landingData = data?.landing;

const slideToAbout = (event) => {
  event.preventDefault();
  const section = document.getElementById("about");
  if (section) {
    const offsetPosition = section.offsetTop;
    document.querySelector(".app-container").scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
};

const Landing = ({ lang }) => {
  const [index, setIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      // Preload the next image before setting the new index
      const img = new Image();
      img.src = imageUrls[nextIndex];

      img.onload = () => {
        setIndex(nextIndex);
        setNextIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
      };
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, [nextIndex]);

  return (
    <div className="landing" id="home">
      <div className="responsive-cover"></div>
      <div className="responsive-title">
        Excellent
        <img className="landing-logo" src={logo} alt="Logo" />
        Tutorat
      </div>
      <div className="responsive-slider">
        <FontAwesomeIcon
          icon={faChevronDown}
          className="slider-icon"
          onClick={slideToAbout}
        />
      </div>
      <div className="carousel-text">{landingData?.at(index)[lang]}</div>
      <div className="carousel-container">
        <div className="carousel">
          {imageUrls.map((src, i) => (
            <img
              key={i}
              src={src}
              alt={`Slide ${i}`}
              className={`carousel-image ${i === index ? "visible" : ""}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: getAppLang(state),
});

export default connect(mapStateToProps)(Landing);