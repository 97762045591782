import React from 'react';
import { connect } from 'react-redux';
import { getAppLang, getUserData } from "../../redux/selectors.js";
import "./styles/WebScheduleSessionDetails.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChalkboardTeacher, faCircleCheck, faCircleXmark, faClock, faExclamationTriangle, faGraduationCap, faTrash } from "@fortawesome/free-solid-svg-icons";
import { sessionCancelThunk, sessionConfirmThunk, sessionDeleteThunk } from "../../redux/thunks.js";

const getPillClass = (status) => {
  switch (status) {
    case "confirmed": return "session-confirmed";
    case "canceled": return "session-canceled";
    case "scheduled": return "session-scheduled";
    default: return "session-unknown";
  }
};

const getCourse = (courseKey, lang) => {
  const courses = {
    math: lang === "en" ? "Math" : "Mathématiques",
    science: lang === "en" ? "Science" : "Sciences",
    english: lang === "en" ? "English" : "Anglais",
    french: lang === "en" ? "French" : "Français",
    physicalScience: lang === "en" ? "Physical Sciences" : "Sciences Physiques",
    other: lang === "en" ? "Other" : "Autre",
    biology: lang === "en" ? "Biology" : "Biologie",
  };
  return courses[courseKey] || (lang === "en" ? "Unknown" : "Inconnu");
};

const getStatusText = (status, lang, faultyCancelation=false) => {
  const statusTexts = {
    confirmed: lang === "en" ? "Confirmed" : "Confirmé",
    canceled: lang === "en" ?
      faultyCancelation ? "Faulty Cancelation" : "Canceled" :
      faultyCancelation ? "Annulation défectueuse" : "Annulé",
    scheduled: lang === "en" ? "Scheduled" : "Planifié",
    pending: lang === "en" ? "Pending" : "En attente",
    unknown: lang === "en" ? "Unknown" : "Inconnu"
  };
  return statusTexts[status] || statusTexts.unknown;
};

const WebScheduleSessionDetails = ({ lang, session, sessionCancel, sessionConfirm, sessionDelete, userData }) => {
  return (
    <div className="web-schedule-session-details">
      
      {/* 🌟 Web Pills 🌟 */}
      <div className={`web-schedule-session-pill ${getPillClass(session?.session?.status)}`}>

        {/* Row 1: Date, Time, Location */}
        <div className="web-schedule-session-row-1">
          <span>{session?.session?.date}</span>
          <span>{session?.session?.time}</span>
          <span>{session?.session?.location}</span>
        </div>

        {/* Row 2: Course & Teacher/Student */}
        <div className="web-schedule-session-row-2">
          <span>{getCourse(session?.course, lang)}</span>
          {userData?.role === "teacher" || userData?.role === "admin" ? (
            <span>
              <FontAwesomeIcon icon={faGraduationCap} className="me-1" />
              {session?.studentName}
            </span>
          ) : (
            <span>
              <FontAwesomeIcon icon={faChalkboardTeacher} className="me-1" />
              {session?.teacherName}
            </span>
          )}
        </div>

        {/* Row 3: Action Buttons */}
        <div className="web-schedule-session-row-3">
          {(userData?.role === "teacher" || userData?.role === "admin") && session?.session?.status === "scheduled" && (
            <div className="web-schedule-session-option" onClick={() => {
              sessionConfirm({
                ...session,
                session: { ...session.session, status: "confirmed", confirmedByTeacher: true }
              });
            }}>
              <FontAwesomeIcon icon={faCircleCheck} className="web-schedule-session-option-icon session-confirm" />
            </div>
          )}

          {(userData?.role === "teacher" || userData?.role === "admin") && session?.session?.status === "confirmed" && (
            <div className="web-schedule-session-option" onClick={() => {
              sessionCancel({
                ...session,
                session: { ...session.session, status: "canceled", cancelationDate: new Date().toISOString().split('T')[0] }
              });
            }}>
              <FontAwesomeIcon icon={faCircleXmark} className="web-schedule-session-option-icon session-cancel" />
            </div>
          )}
          {(session?.session?.status === "canceled" || session?.session?.status === "pending") && !session?.session?.faultyCancelation && (
            <div className="web-schedule-session-option" onClick={() => sessionDelete(session)}>
              <FontAwesomeIcon icon={faTrash} className="web-schedule-session-option-icon session-delete" />
            </div>
          )}
          {session?.session?.status === "canceled" && session?.session?.faultyCancelation && (
            <div className="web-schedule-session-option">
              <FontAwesomeIcon icon={faExclamationTriangle} className="web-schedule-session-option-icon session-delete" />
            </div>
          )}
        </div>

        {/* Status Text */}
        <div className="web-schedule-session-status">
          {getStatusText(session?.session?.status, lang, session?.session?.faultyCancelation)}
        </div>

      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: getAppLang(state),
  userData: getUserData(state),
});

const mapDispatchToProps = (dispatch) => ({
  sessionCancel: (data) => dispatch(sessionCancelThunk(data)),
  sessionConfirm: (data) => dispatch(sessionConfirmThunk(data)),
  sessionDelete: (data) => dispatch(sessionDeleteThunk(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WebScheduleSessionDetails);
