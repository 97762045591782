import storage from "redux-persist/lib/storage";
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { thunk } from 'redux-thunk';
import { persistReducer, persistStore } from "redux-persist";
const { appReducer } = require("./reducers.js");

const rootReducer = combineReducers({
  app: appReducer
});

const persistConfig = {
  key: "root",
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);