import { getApp, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import firebaseConfig from './realFirebaseConfig.json';

const APP_NAME = 'excellent-tutorat';
let app;
try {
  app = getApp(APP_NAME);
} catch {
  app = initializeApp(firebaseConfig, APP_NAME);
}

const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };