import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../firebase.js";
import {
  errorAction,
  loadData,
  loadingEnd,
  loadingStart,
  toggleLang,
  userRegister,
  userLogin,
  userLogout,
  sessionDelete,
  sessionConfirm,
  sessionCancel,
  sessionAdd,
} from "./actions.js";
import { collection, deleteDoc, doc as firestoreDoc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";

export const loadingStartThunk = () => async (dispatch) => {
  try {
    dispatch(loadingStart());
  } catch (e) {
    //console.log(e);
    dispatch(errorAction(e));
  }
};

export const loadingEndThunk = () => async (dispatch) => {
  try {
    dispatch(loadingEnd());
  } catch (e) {
    //console.log(e);
    dispatch(errorAction(e));
  }
};

export const loadDataThunk = (userEmail) => async (dispatch) => {
  try {
    dispatch(loadingStart());

    if (!userEmail) {
      throw new Error("No user email provided");
    }

    const userDocRef = firestoreDoc(collection(db, "users"), userEmail);
    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists()) {
      throw new Error(`User document not found for ${userEmail}`);
    }

    const userData = userDoc.data();

    // Query user info from "people" collection
    const peopleQuery = query(collection(db, "people"), where("id", "==", userData.id));
    const peopleSnapshot = await getDocs(peopleQuery);

    if (peopleSnapshot.empty) {
      throw new Error("User data not found in people collection");
    }

    const userPeopleData = peopleSnapshot.docs[0].data();
    let children = [];
    let sessions = [];
    let people = [];

    // Fetch dependents in parallel if user is a parent
    if (userPeopleData.role === "parent" && userPeopleData.dependents?.length) {
      const dependentPromises = userPeopleData.dependents.map((depId) => getDoc(firestoreDoc(db, "people", depId)));
      const dependentDocs = await Promise.all(dependentPromises);
      children = dependentDocs.map((doc) => doc.data());
    }

    // Fetch sessions in parallel
    const sessionsQuery = query(
      collection(db, "sessions"),
      where((userPeopleData.role === "teacher" || userPeopleData.role === "admin") ? "teacher" : "student", "==", userPeopleData.id)
    );
    const sessionsSnapshot = await getDocs(sessionsQuery);

    if (!sessionsSnapshot.empty) {
      sessions = await Promise.all(
        sessionsSnapshot.docs.map(async (doc) => {
          const session = doc.data();
          const [teacherDoc, studentDoc] = await Promise.all([
            getDoc(firestoreDoc(db, "people", session.teacher)),
            getDoc(firestoreDoc(db, "people", session.student)),
          ]);
          return {
            ...session,
            teacherName: teacherDoc.exists() ? teacherDoc.data().firstName + " " + teacherDoc.data().lastName : "Unknown",
            studentName: studentDoc.exists() ? studentDoc.data().firstName + " " + studentDoc.data().lastName : "Unknown",
          };
        })
      );
    }

    // Fetch all people data if user is an admin or teacher
    if (["teacher", "admin"].includes(userPeopleData.role)) {
      const peopleSnapshot = await getDocs(collection(db, "people"));
      people = peopleSnapshot.docs.map((doc) => doc.data());
    }

    dispatch(
      loadData({
        user: userPeopleData,
        children: userPeopleData.role === "parent" ? children : undefined,
        sessions,
        people,
      })
    );

    dispatch(loadingEnd());
  } catch (e) {
    console.error("Error loading data:", e.message);
    dispatch(errorAction(e.message));
    dispatch(loadingEnd());
  }
};

export const toggleLangThunk = (lang) => async (dispatch) => {
  try {
    if (lang !== "en" && lang !== "fr") {
      throw new Error("Invalid language");
    }

    dispatch(toggleLang(lang));
  } catch (e) {
    //console.log(e);
    dispatch(errorAction(e));
  }
};

export const userLoginThunk = (email, password) => async (dispatch) => {
  try {
    if (!email || !password) {
      throw new Error("Invalid credentials");
    }

    const userCredentials = await signInWithEmailAndPassword(
      auth, email, password
    );

    //console.log(`User logged in: ${userCredentials.user.displayName}`);

    dispatch(userLogin(userCredentials.user));
  } catch (e) {
    //console.log(e);
    dispatch(errorAction(e));
  }
};

export const userLogoutThunk = () => async (dispatch) => {
  try {
    await auth.signOut();
    dispatch(userLogout());
  } catch (e) {
    //console.log(e);
    dispatch(errorAction(e));
  }
};

export const userRegisterThunk = (data) => async (dispatch) => {
  try {
    //console.log("Registering user");

    const user = await createUserWithEmailAndPassword(
      auth, data?.user?.email, data?.user?.password
    );

    const { password, ...userData } = data?.user;

    const userCol = collection(db, "users");
    const userDocRef = firestoreDoc(userCol, data?.user?.email);
    await setDoc(userDocRef, userData);

    const peopleCol = collection(db, "people");
    data?.people?.forEach(async (person) => {
      const personDocRef = firestoreDoc(peopleCol, person.id);
      await setDoc(personDocRef, person);
    });

    //console.log("User registered");

    dispatch(userRegister(user));
  } catch (e) {
    //console.log(e);
    dispatch(errorAction(e));
  }
};

export const sessionAddThunk = (session) => async (dispatch) => {
  try {
    const {teacherName, studentName, ...newSession} = session;
    const sessionsCol = collection(db, "sessions");
    const sessionDocRef = firestoreDoc(sessionsCol, session.id);
    await setDoc(sessionDocRef, newSession);


    dispatch(sessionAdd(session));
  } catch (e) {
    //console.log(e);
    dispatch(errorAction(e));
  }
};

export const sessionDeleteThunk = (session) => async (dispatch) => {
  try {
    const sessionsCol = collection(db, "sessions");
    const sessionDocRef = firestoreDoc(sessionsCol, session.id);
    await deleteDoc(sessionDocRef);

    //console.log(`Deleting session: ${session.id}`);

    dispatch(sessionDelete(session));
  } catch (e) {
    //console.log(e);
    dispatch(errorAction(e));
  }
};

export const sessionConfirmThunk = (session) => async (dispatch) => {
  try {
    const sessionsCol = collection(db, "sessions");
    const sessionDocRef = firestoreDoc(sessionsCol, session.id);
    await setDoc(sessionDocRef, session);

    //console.log(`Confirming session: ${session.id}`);

    dispatch(sessionConfirm(session));
  } catch (e) {
    //console.log(e);
    dispatch(errorAction(e));
  }
};

export const sessionCancelThunk = (session) => async (dispatch) => {
  try {
    const sessionsCol = collection(db, "sessions");
    const sessionDocRef = firestoreDoc(sessionsCol, session.id);
    await setDoc(sessionDocRef, session);

    //console.log(`Canceling session: ${session.id}`);

    dispatch(sessionCancel(session));
  } catch (e) {
    //console.log(e);
    dispatch(errorAction(e));
  }
};