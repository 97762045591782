export const LOADING_START = "LOADING_START";
export const loadingStart = () => ({
  type: LOADING_START
});

export const LOADING_END = "LOADING_END";
export const loadingEnd = () => ({
  type: LOADING_END
});

export const LOAD_DATA = "LOAD_DATA";
export const loadData = (data) => ({
  type: LOAD_DATA,
  payload: { data }
});

export const TOGGLE_LANG = "TOGGLE_LANG";
export const toggleLang = (lang) => ({
  type: TOGGLE_LANG,
  payload: { lang }
});

export const USER_LOGIN = "USER_LOGIN";
export const userLogin = (user) => ({
  type: USER_LOGIN,
  payload: { user }
});

export const USER_LOGOUT = "USER_LOGOUT";
export const userLogout = () => ({
  type: USER_LOGOUT
});

export const USER_REGISTER = "USER_REGISTER";
export const userRegister = (newUser) => ({
  type: USER_REGISTER,
  payload: { newUser }
});

export const ERROR_ACTION = "ERROR_ACTION";
export const errorAction = (error) => ({
  type: ERROR_ACTION,
  payload: { error }
});

export const SESSION_ADD = "SESSION_ADD";
export const sessionAdd = (newSession) => ({
  type: SESSION_ADD,
  payload: { newSession }
});

export const SESSION_DELETE = "SESSION_DELETE";
export const sessionDelete = (oldSession) => ({
  type: SESSION_DELETE,
  payload: { oldSession }
});

export const SESSION_CONFIRM = "SESSION_CONFIRM";
export const sessionConfirm = (session) => ({
  type: SESSION_CONFIRM,
  payload: { session }
});

export const SESSION_CANCEL = "SESSION_CANCEL";
export const sessionCancel = (canceledSession) => ({
  type: SESSION_CANCEL,
  payload: { canceledSession }
});