import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import "./styles/Parentaldentification.css";
import { getAppLang } from "../../redux/selectors.js";
import RegisterModalTextField from "./RegisterModalTextField.jsx";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { v4 as uuid } from "uuid";
import ChildInfoRow from "./ChildInfoRow.jsx";
import MobileChildInfoRow from "./MobileChildInfoRow.jsx";

const Parentaldentification = ({
  clearChildErrors,
  clearAllChildErrors,
  childData,
  errors = { parent: {}, children: {} }, // 👈 Ensure errors object always has required properties
  initChildErrors,
  lang,
  parentData = {}, // 👈 Ensure parentData is defined
  setChildData,
  setParentData,
  updateChildErrors,
  updateErrors
}) => {
  const [formIsFor, setFormIsFor] = useState(parentData.hasChildren ? "children" : "self");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const childContainerRef = useRef(null);

  useEffect(() => {
    // Resize event listener for real-time responsiveness
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const updateFormIsFor = (value) => {
    setFormIsFor(value);
    updateParentData("hasChildren", value === "children");

    if (value === "self") {
      setChildData({});
      clearAllChildErrors();
    } else {
      const id = uuid();
      setChildData({
        [id]: {
          id,
          firstName: "",
          middleName: "",
          lastName: "",
          dateOfBirth: null
        }
      });
      initChildErrors(id);
    }
  };

  const updateParentData = (key, value) => {
    setParentData({
      ...parentData,
      [key]: value
    });
  };

  const updateChildData = (id, key, value) => {
    setChildData({
      ...childData,
      [id]: {
        ...childData[id],
        [key]: value
      }
    });
  };

  const addChild = () => {
    const id = uuid();
    setChildData({
      ...childData,
      [id]: {
        id,
        firstName: "",
        middleName: "",
        lastName: "",
        dateOfBirth: null
      }
    });
    initChildErrors(id);
  };

  const removeChild = (id) => {
    const newChildData = { ...childData };
    delete newChildData[id];
    setChildData(newChildData);
    clearChildErrors(id);

    if (Object.keys(newChildData).length === 0) {
      updateFormIsFor("self");
    }
  };

  useEffect(() => {
    if (formIsFor === "children" && childContainerRef.current) {
      childContainerRef.current.scrollTop = childContainerRef.current.scrollHeight;
    }
  }, [formIsFor, childData]);

  return (
    <div className="parental-identification-container">
      <h2 className="parental-identification-header">
        {lang === "en" ? "Parental Identification" : "Identification parentale"}
      </h2>

      <div className="parental-identification-form">
        <RegisterModalTextField
          label={lang === "en" ? "First Name *" : "Prénom *"}
          stateVal={parentData.firstName}
          stateFunc={(value) => updateParentData("firstName", value)}
          error={errors.parent.firstName}
          updateError={(value) => updateErrors("firstName", value)}
        />
        <RegisterModalTextField
          label={lang === "en" ? "Middle Name (Optional)" : "Deuxième prénom (facultatif)"}
          stateVal={parentData.middleName}
          stateFunc={(value) => updateParentData("middleName", value)}
        />
        <RegisterModalTextField
          label={lang === "en" ? "Last Name *" : "Nom *"}
          stateVal={parentData.lastName}
          stateFunc={(value) => updateParentData("lastName", value)}
          error={errors.parent.lastName}
          updateError={(value) => updateErrors("lastName", value)}
        />
        <RegisterModalTextField
          label={lang === "en" ? "Email *" : "Courriel *"}
          stateVal={parentData.email}
          stateFunc={(value) => updateParentData("email", value)}
          error={errors.parent.email}
          updateError={(value) => updateErrors("email", value)}
        />
        <RegisterModalTextField
          label={lang === "en" ? "Phone Number *" : "Numéro de téléphone *"}
          stateVal={parentData.phoneNumber}
          stateFunc={(value) => updateParentData("phoneNumber", value)}
          error={errors.parent.phoneNumber}
          updateError={(value) => updateErrors("phoneNumber", value)}
          phoneField={true}
        />
      </div>

      <h3 className="section-header">{lang === "en" ? "Who am I signing up for?" : "J'inscris qui?"}</h3>
      <RadioGroup
        row
        value={formIsFor}
        onChange={(e) => updateFormIsFor(e.target.value)}
        name="row-radio-buttons-group"
        className="radio-group"
      >
        <FormControlLabel value="self" control={<Radio />} label={lang === "en" ? "Myself" : "Moi-même"} />
        <FormControlLabel value="children" control={<Radio />} label={lang === "en" ? "My Children" : "Mon (mes) enfant(s)"} />
      </RadioGroup>

      {formIsFor === "children" && (
        <div className="parental-child-container" ref={childContainerRef}>
          <h3 className="section-header">{lang === "en" ? "Child(ren)'s Information" : "Information d'enfant(s)"}</h3>
          {Object.keys(childData).map((id, index) =>
            isMobile ? (
              <MobileChildInfoRow
                key={id}
                childErrors={errors.children[id]}
                data={childData[id]}
                deleteChild={() => removeChild(id)}
                rowIndex={index}
                updateChildData={(key, value) => updateChildData(id, key, value)}
                updateChildErrors={(key, value) => updateChildErrors(id, key, value)}
              />
            ) : (
              <ChildInfoRow
                key={id}
                childErrors={errors.children[id]}
                data={childData[id]}
                deleteChild={() => removeChild(id)}
                rowIndex={index}
                updateChildData={(key, value) => updateChildData(id, key, value)}
                updateChildErrors={(key, value) => updateChildErrors(id, key, value)}
              />
            )
          )}
          <button className="parental-identification-button" onClick={addChild}>
            {lang === "en" ? "Add Another Child" : "Ajouter un autre enfant"}
          </button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: getAppLang(state),
});

export default connect(mapStateToProps)(Parentaldentification);