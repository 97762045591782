import React, { useState } from "react";
import { connect } from "react-redux";
import { Stepper, Step, StepLabel, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "./styles/RegisterStepper.css";
import { getAppLang } from "../../redux/selectors.js";
import Parentaldentification from "./Parentaldentification.jsx";
import { v4 as uuid } from "uuid";
import CoursesAndSchedule from "./CoursesAndSchedule.jsx";
import Agreenment from "./Agreenment.jsx";
import { userRegisterThunk } from "../../redux/thunks.js";

const RegisterStepper = ({ closeModal, lang, userRegister }) => {
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    { index: 1, text: { "en": "Identification", "fr": "Identification" } },
    { index: 2, text: { "en": "Courses & Schedule", "fr": "Cours & Disponibilité" } },
    { index: 3, text: { "en": "Agreement", "fr": "Accord" } },
  ];

  const [parentData, setParentData] = useState({
    id: uuid(),
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    hasChildren: false,
  });

  const [childData, setChildData] = useState({});
  const [courseData, setCourseData] = useState({
    math: false,
    science: false,
    physicalScience: false,
    biology: false,
    french: false,
    english: false,
    other: ""
  });

  const [scheduleData, setScheduleData] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false
  });

  const [agreementData, setAgreementData] = useState({
    agree: false,
    fullName: "",
    password: "",
    confirmPassword: ""
  });

  const [errors, setErrors] = useState({
    parent: { firstName: "", lastName: "", email: "", phoneNumber: "" },
    children: {},
    courses: "",
    schedule: "",
    validation: { fullName: "", password: "", confirmPassword: "" }
  });

  const updateErrors = (key, value) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      parent: { ...prevErrors.parent, [key]: value }
    }));
  };

  const clearChildErrors = (id) => {
    setErrors((prevErrors) => {
      const newChildrenErrors = { ...prevErrors.children };
      delete newChildrenErrors[id];
      return { ...prevErrors, children: newChildrenErrors };
    });
  };

  const clearAllChildErrors = () => setErrors((prevErrors) => ({ ...prevErrors, children: {} }));

  const initChildErrors = (id) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      children: { ...prevErrors.children, [id]: { firstName: "", lastName: "", dateOfBirth: "" } }
    }));
  };

  const updateChildErrors = (id, key, value) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      children: {
        ...prevErrors.children,
        [id]: { ...prevErrors.children[id], [key]: value }
      }
    }));
  };

  const updateCourseError = (value) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      courses: value
    }));
  };

  const updateScheduleError = (value) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      schedule: value
    }));
  };

  const updateValidationErrors = (key, value) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      validation: {
        ...prevErrors.validation,
        [key]: value
      }
    }));
  };

  const validateParentData = () => {
    const parentErrors = {};
    let valid = true;

    if (parentData.firstName === "") {
      valid = false;
      parentErrors.firstName = lang === "en"
        ? "First Name is required"
        : "Le Prénom est requis";
    }
    if (parentData.lastName === "") {
      valid = false;
      parentErrors.lastName = lang === "en"
        ? "Last Name is required"
        : "Le Nom est requis";
    }
    if (parentData.email === "") {
      valid = false;
      parentErrors.email = lang === "en"
        ? "Email is required"
        : "Le Courriel est requis";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(parentData.email)
    ) {
      valid = false;
      parentErrors.email = lang === "en"
        ? "Email is invalid"
        : "Le Courriel est invalide";
    }
    if (parentData.phoneNumber === "") {
      valid = false;
      parentErrors.phoneNumber = lang === "en"
        ? "Phone Number is required"
        : "Le Numéro de téléphone est requis";
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      parent: parentErrors
    }));

    return valid;
  };

  const validateChildData = () => {
    const childErrors = {};
    let valid = true;

    Object.keys(childData).forEach((id) => {
      if (childData[id].firstName === "") {
        valid = false;
        childErrors[id] = {
          ...childErrors[id],
          firstName: lang === "en"
            ? "First Name is required"
            : "Le Prénom est requis"
        };
      }
      if (childData[id].lastName === "") {
        valid = false;
        childErrors[id] = {
          ...childErrors[id],
          lastName: lang === "en"
            ? "Last Name is required"
            : "Le Nom est requis"
        };
      }
      if (childData[id].dateOfBirth === null) {
        valid = false;
        childErrors[id] = {
          ...childErrors[id],
          dateOfBirth: lang === "en"
            ? "Date of Birth is required"
            : "La Date de naissance est requise"
        };
      }
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      children: childErrors
    }));

    return valid;
  };

  const handleNext = () => {
    let valid = true;
    if (activeStep < steps.length - 1) {
      if (activeStep === 0) {
        valid = validateParentData();

        if (parentData?.hasChildren) {
          valid = validateChildData();
          //console.log(childData);
          Object.keys(childData).forEach((id) => {
            if (childData[id].dateOfBirth) {
              //console.log(`Child ${id} Date of Birth: ${new Date(childData[id].dateOfBirth).toLocaleDateString()}`);
              //console.log(childData[id].dateOfBirth);
              //console.log(childData[id].dateOfBirth.isValid() && childData[id].dateOfBirth !== null);
              //console.log(childData[id].dateOfBirth.format("YYYY-MM-DD"));
            }
          });
        }

        if (!valid) {
          return;
        }
      }
    }

    if (activeStep === 1) {
      if (!Object.values(courseData).includes(true)) {
        updateCourseError(lang === "en"
          ? "At least one course must be selected"
          : "Au moins un cours doit être sélectionné"
        );
        valid = false;
      }
      if (!Object.values(scheduleData).includes(true)) {
        updateScheduleError(lang === "en"
          ? "At least one day must be selected"
          : "Au moins un jour doit être sélectionné"
        );
        valid = false;
      }

      if (!valid) {
        return;
      }
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prev) => prev - 1);
    }
  };

  const readyForConfirmation = () => {
    let valid = true;

    setErrors((prevErrors) => ({
      ...prevErrors,
      validation: {
        fullName: "",
        password: "",
        confirmPassword: ""
      }
    }));

    if (activeStep === 2) {
      if (agreementData.fullName === "") {
        updateValidationErrors("fullName", lang === "en"
          ? "Full Name is required"
          : "Nom Complet est requis"
        );
        valid = false;
      } else if (
        agreementData.fullName
        !== parentData.firstName + " " + parentData.lastName
      ) {
        updateValidationErrors("fullName", lang === "en"
          ? "Full Name must match Parent Name (First Last)"
          : "Nom Complet doit correspondre au Nom du Parent (Prénom Nom)"
        );
        valid = false;
      }

      if (agreementData.password === "") {
        updateValidationErrors("password", lang === "en"
          ? "Password is required"
          : "Mot de Passe est requis"
        );
        valid = false;
      }

      if (agreementData.confirmPassword === "") {
        updateValidationErrors("confirmPassword", lang === "en"
          ? "Please confirm your password"
          : "Veuillez confirmer votre mot de passe"
        );
        valid = false;
      }

      if (agreementData.password !== agreementData.confirmPassword) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          validation: {
            ...prevErrors.validation,
            password: lang === "en"
              ? "Passwords do not match"
              : "Les mots de passe ne correspondent pas",
            confirmPassword: lang === "en"
              ? "Passwords do not match"
              : "Les mots de passe ne correspondent pas"
          }
        }));
        valid = false;
      }

      if (agreementData.password.length < 8) {
        updateValidationErrors("password", lang === "en"
          ? "Password must be at least 8 characters"
          : "Le mot de passe doit comporter au moins 8 caractères"
        );
        valid = false;
      }

      if (!/[0-9]/.test(agreementData.password)) {
        updateValidationErrors("password", lang === "en"
          ? "Password must contain at least one number"
          : "Le mot de passe doit contenir au moins un chiffre"
        );
        valid = false;
      }

      if (!/[A-Z]/.test(agreementData.password)) {
        updateValidationErrors("password", lang === "en"
          ? "Password must contain at least one uppercase letter"
          : "Le mot de passe doit contenir au moins une lettre majuscule"
        );
        valid = false;
      }

      return valid;
    };

  };

  const register = () => {
    let valid = readyForConfirmation();

    if (!valid) {
      return;
    }

    const toTitleCase = (str) => {
      if (str === "") {
        return "";
      }

      return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
    };

    const registrationData = {
      user: {
        email: parentData.email.toLowerCase(),
        password: agreementData.password,
        displayName:
          toTitleCase(parentData?.firstName)
          + " "
          + toTitleCase(parentData?.lastName),
        id: parentData?.id,
      },
      people: [
        parentData,
        ...Object.values(childData)
      ].map((person) => ({
        id: person?.id,
        firstName: toTitleCase(person?.firstName),
        middleName: toTitleCase(person?.middleName),
        lastName: toTitleCase(person?.lastName),
        dateOfBirth: person?.dateOfBirth === undefined
          ? null
          : person?.dateOfBirth.format("YYYY-MM-DD"),
        role: person?.id === parentData.id ? "parent" : "student",
        courses: Object
          .keys(courseData).filter((course) => courseData[course]),
        availability: Object
          .keys(scheduleData).filter((day) => scheduleData[day]),
        dependents: person?.id === parentData.id ?
          Object.values(childData).map((child) => child.id) :
          [],
        phoneNumber: parentData?.phoneNumber
      }))
    };

    //console.log(registrationData);
    userRegister(registrationData);
    closeModal();
  };

  const StepIconComponent = (props) => {
    const { active, completed, icon } = props;
    return (
      <div className={`step-icon ${active ? "active" : ""} ${completed ? "completed" : ""}`}>
        {steps[icon - 1].index}
      </div>
    );
  };

  return (
    <div className="register-stepper-container">
      <div className="register-stepper-content">
        {activeStep === 0 && (
          <Parentaldentification
            childData={childData}
            clearAllChildErrors={clearAllChildErrors}
            clearChildErrors={clearChildErrors}
            errors={errors}
            initChildErrors={initChildErrors}
            parentData={parentData}
            setChildData={setChildData}
            setParentData={setParentData}
            updateChildErrors={updateChildErrors}
            updateErrors={updateErrors}
          />
        )}
        {
          activeStep === 1 &&
          <CoursesAndSchedule
            courseData={courseData}
            errors={errors}
            scheduleData={scheduleData}
            setCourseData={setCourseData}
            setScheduleData={setScheduleData}
            updateCourseError={updateCourseError}
            updateScheduleError={updateScheduleError}
          />
        }
        {
          activeStep === 2 &&
          <Agreenment
            agreementData={agreementData}
            errors={errors}
            setAgreementData={setAgreementData}
            updateValidationErrors={updateValidationErrors}
          />
        }
      </div>

      {/* Navigation Buttons at Bottom */}
      <div className="register-stepper-buttons">
        {activeStep > 0 && (
          <IconButton className="register-stepper-button" onClick={handleBack}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </IconButton>
        )}
        {activeStep < steps.length - 1 && (
          <IconButton className="register-stepper-button" onClick={handleNext}>
            <FontAwesomeIcon icon={faChevronRight} />
          </IconButton>
        )}
      </div>

      {/* Stepper */}
      <Stepper activeStep={activeStep} alternativeLabel className="register-stepper">
        {steps.map((step, index) => (
          <Step key={step.index} completed={activeStep > index}>
            <StepLabel StepIconComponent={StepIconComponent}>{step.text[lang]}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {/* Confirmation Button */}
      {activeStep === steps.length - 1 && (
        <button className="register-stepper-confirm-button"
          onClick={() => {
            if (readyForConfirmation()) {
              register();
            }
          }}
          onTouchEnd={() => {
            if (readyForConfirmation()) {
              register();
            }
          }}
        >
          <FontAwesomeIcon icon={faCheck} />
        </button>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: getAppLang(state),
});

const mapDispatchToProps = (dispatch) => ({
  userRegister: () => dispatch(userRegisterThunk()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterStepper);