import React, { useRef } from "react";
import { connect } from "react-redux";
import { getAppLang } from "../../redux/selectors.js";
import { Checkbox, FormControlLabel } from "@mui/material";
import "./styles/CoursesAndSchedule.css";
import RegisterModalTextField from "./RegisterModalTextField.jsx";

const CoursesAndSchedule = ({
  courseData,
  errors,
  lang,
  scheduleData,
  setCourseData,
  setScheduleData,
  updateCourseError,
  updateScheduleError,
}) => {
  const otherCourseRef = useRef();

  const toggleCourse = (course) => {
    setCourseData({ ...courseData, [course]: !courseData[course] });
    updateCourseError("");
  };

  const updateOtherCourse = (value) => {
    setCourseData({ ...courseData, other: value });
    updateCourseError("");
  };

  const toggleSchedule = (day) => {
    setScheduleData({ ...scheduleData, [day]: !scheduleData[day] });
    updateScheduleError("");
  };

  return (
    <div className="courses-schedule-container">
      {/* Courses Section */}
      <div className="courses-schedule-header">
        {lang === "en" ? "I want to enroll in *" : "Je veux m'inscrire en *"}
      </div>

      <div className="courses-grid">
        {[
          { id: "math", en: "Math", fr: "Mathématiques" },
          { id: "science", en: "Science", fr: "Sciences" },
          { id: "physicalScience", en: "Physical Sciences", fr: "Sciences Physiques" },
          { id: "biology", en: "Biology", fr: "Biologie" },
          { id: "french", en: "French", fr: "Français" },
          { id: "english", en: "English", fr: "Anglais" },
        ].map((course) => (
          <FormControlLabel
            key={course.id}
            control={<Checkbox checked={courseData[course.id]} onClick={() => toggleCourse(course.id)} />}
            label={lang === "en" ? course.en : course.fr}
          />
        ))}

        {/* "Other" Course Field */}
        <div className="other-course-container">
          <FormControlLabel
            className="other-course-checkbox"
            control={
              <Checkbox
                checked={courseData.other !== ""}
                onClick={() => {
                  if (courseData.other === "") {
                    otherCourseRef.current.querySelector("input").focus();
                  } else {
                    toggleCourse("other");
                  }
                }}
              />
            }
            label=""
          />
          <div className="other-course-field">
            <RegisterModalTextField
              label={lang === "en" ? "Other" : "Autre"}
              stateFunc={updateOtherCourse}
              stateVal={courseData.other}
              reference={otherCourseRef}
            />
          </div>
        </div>
      </div>
      <div className="courses-schedule-error">{errors?.courses}</div>

      {/* Availability Section */}
      <div className="courses-schedule-header mt-2">
        {lang === "en"
          ? "Availability (multiple choices allowed) *"
          : "Disponibilité (plusieurs choix possibles) *"}
      </div>

      <div className="availability-grid">
        {[
          { id: "monday", en: "Monday (night)", fr: "Lundi (soir)" },
          { id: "tuesday", en: "Tuesday (night)", fr: "Mardi (soir)" },
          { id: "wednesday", en: "Wednesday (night)", fr: "Mercredi (soir)" },
          { id: "thursday", en: "Thursday (night)", fr: "Jeudi (soir)" },
          { id: "friday", en: "Friday (night)", fr: "Vendredi (soir)" },
          { id: "saturday", en: "Saturday (morning & night)", fr: "Samedi (matin & soir)" },
          { id: "sunday", en: "Sunday (morning & night)", fr: "Dimanche (matin & soir)" },
        ].map((day) => (
          <FormControlLabel
            key={day.id}
            control={<Checkbox checked={scheduleData[day.id]} onClick={() => toggleSchedule(day.id)} />}
            label={lang === "en" ? day.en : day.fr}
          />
        ))}
      </div>
      <div className="courses-schedule-error">{errors?.schedule}</div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: getAppLang(state),
});

export default connect(mapStateToProps)(CoursesAndSchedule);