import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import "./styles/ScheduleModal.css";
import { getAppLang, getSessionData, getUserData } from "../redux/selectors.js";
import logo from "../images/logo.png";
import MobileScheduleSessionDetails from "../components/schedule/MobileScheduleSessionDetails.jsx";
import WebScheduleSessionDetails from "../components/schedule/WebScheduleSessionDetails.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCircleCheck, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import NewSessionModal from "./NewSessionModal.jsx";

const ScheduleModal = ({
  closeModal,
  modalRef,
  lang,
  userData,
  sessionData,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [modalState, setModalState] = useState({
    status: false, ref: useRef()
  });

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      document.body.style.overflow = 'auto';
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      //console.log("clicked");
      closeModal();
    }
  };

  const handleFilterChange = (filter) => {
    if (filter === selectedFilter && filter !== "all") {
      setSelectedFilter("all");
    } else if (filter !== selectedFilter) {
      setSelectedFilter(filter);
    } else {
      return;
    }
  };

  const closeCreationModal = () => {
    setModalState({
      ...modalState,
      status: false
    });
  };

  const openCreationModal = (modal) => {
    setModalState({
      ...modalState,
      status: true
    });
  };

  // Filter sessions based on the selected filter
  const filteredSessions = sessionData
    ?.sort((a, b) => {
      const dateA = new Date(`${a?.session?.date}T${a?.session?.time}`);
      const dateB = new Date(`${b?.session?.date}T${b?.session?.time}`);
      return dateB - dateA;
    })
    ?.filter((session) => {
      if (selectedFilter === "all") return true;
      if (selectedFilter === "confirmed") return session?.session?.status === "confirmed";
      if (selectedFilter === "scheduled") return session?.session?.status === "scheduled";
      if (selectedFilter === "canceled") return session?.session?.status === "canceled";
      return false;
    });

  return (
    <div className="schedule-modal-backdrop" onClick={handleBackdropClick} ref={modalRef}>
      <div className="schedule-modal">
        <div style={{
          display: modalState?.status ? "block" : "none"
        }}>
          <NewSessionModal
            closeModal={() => closeCreationModal()}
            modalRef={modalState?.ref}
          />
        </div>
        <div className="schedule-modal-logo">
          <img className="modal-logo" src={logo} alt="Logo" />
        </div>
        <div className="schedule-modal-header">
          <div className="schedule-filter-div">
            <div
              className={`filter-button filter-button-all ${selectedFilter === "all" ? "" : ""}`}
              onClick={() => handleFilterChange("all")}
            >
              <FontAwesomeIcon
                icon={
                  selectedFilter === "all" ? faCircleCheck : faCircle
                }
                className="text-xl lg:text-4xl"
              />
            </div>
            <div
              className={`filter-button filter-button-confirmed ${selectedFilter === "confirmed" ? "" : ""}`}
              onClick={() => handleFilterChange("confirmed")}
            >
              <FontAwesomeIcon
                icon={
                  selectedFilter === "confirmed" ? faCircleCheck : faCircle
                }
                className="text-xl lg:text-4xl"
              />
            </div>
            <div
              className={`filter-button filter-button-scheduled ${selectedFilter === "scheduled" ? "" : ""}`}
              onClick={() => handleFilterChange("scheduled")}
            >
              <FontAwesomeIcon
                icon={
                  selectedFilter === "scheduled" ? faCircleCheck : faCircle
                }
                className="text-xl lg:text-4xl"
              />
            </div>
            <div
              className={`filter-button filter-button-canceled ${selectedFilter === "canceled" ? "" : ""}`}
              onClick={() => handleFilterChange("canceled")}
            >
              <FontAwesomeIcon
                icon={
                  selectedFilter === "canceled" ? faCircleCheck : faCircle
                }
                className="text-xl lg:text-4xl"
              />
            </div>
          </div>

          <div className="schedule-modal-header-title">
            {lang === "en" ? "Schedule" : "Horaire"}
          </div>

          <div className="w-1/3 flex justify-end">
            {(userData?.role === "teacher" || userData?.role === "admin") && (
              <div className="schedule-modal-option" onClick={() => openCreationModal()}>
                <FontAwesomeIcon
                  icon={faCirclePlus}
                  className="schedule-modal-option-icon session-new"
                />
              </div>
            )}
          </div>
        </div>

        <div className={`schedule-modal-body ${isMobile ? "mobile" : "web"}`}>
          {filteredSessions?.map((session, index) =>
            isMobile ? (
              <MobileScheduleSessionDetails key={index} session={session} />
            ) : (
              <WebScheduleSessionDetails key={index} session={session} />
            )
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: getAppLang(state),
  userData: getUserData(state),
  sessionData: getSessionData(state),
});

export default connect(mapStateToProps)(ScheduleModal);