import emailjs from '@emailjs/browser';
import emailConfig from "./emailJsConfig.json";

export const sendEmail = async (
  messageData, sucessFunc, errorFunc
) => {
  const { serviceId, templateId, publicKey } = emailConfig?.excellentTutorat;

  emailjs.init({
    publicKey: publicKey,
    blockHeadless: true,
  });

  try {
    await emailjs.send(serviceId, templateId, {
      ...messageData
    });

    //console.log(`Email sent: ${response.status} - ${response.text}`);
    sucessFunc();
  } catch (erorr) {
    //console.log(erorr);
    errorFunc();
  }
};