export const appLoading = state => state?.app?.loading;
export const appLoaded = state => state?.app?.loaded;

export const getAppLang = state => state?.app?.lang;
export const getUser = state => state?.app?.user;
export const getData = state => state?.app?.data;
export const getUserData = state => state?.app?.data?.user;
export const getSessionData = state => state?.app?.data?.sessions;

export const getPeople = state => state?.app?.data?.people;
export const getAllTeachers = state => state?.app?.data?.people
  ?.filter(person => 
    person?.role === "teacher" ||
    person?.role === "admin"
  );
export const getAllStudents = state => state?.app?.data?.people
  ?.filter(person => 
    person?.role === "student" ||
    person?.role === "parent"
  );