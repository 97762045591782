import React from 'react';
import { connect } from 'react-redux';
import "./styles/About.css";

import data from "../data/appData.json";
import { getAppLang } from "../redux/selectors.js";

const aboutData = data?.about;

const teachersImages = [
  require("../images/teachers-college.png"),
  require("../images/quebec-education.jpg"),
  require("../images/france.png"),
  require("../images/english.webp"),
];

const About = ({ lang }) => {
  return (
    <div className="about" id="about">
      <div className="about-header">{aboutData?.header?.title[lang]}</div>
      <div className="about-header-body">{aboutData?.header?.body[lang]}</div>
      <div className="about-subheader">{aboutData?.subheader?.body[lang]}</div>
      <div className="about-teachers">
        <div className="about-teachers-header">
          {aboutData?.teachers?.title[lang]}:
        </div>
        <div className="about-teachers-container">
          {aboutData?.teachers?.contentList?.map((teacher, index) => (
            <div key={index} className={`about-teacher ${
              index === aboutData?.teachers?.contentList.length - 1 ?
                "border-b-0" : ""
            }`}>
              <img
                className="about-teacher-image"
                src={teachersImages[index]}
                alt={teacher.name}
              />
              <div className="about-teacher-content">{teacher[lang]}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="about-subjects">
        <div className="about-subjects-header">
          {aboutData?.subjects?.title[lang]}:
        </div>
        <div className="about-subjects-container">
          {aboutData?.subjects?.contentList?.map((subject, index) => (
            <div
              key={index}
              className={`about-subject ${
                index === aboutData?.subjects?.contentList.length - 1 ?
                  "" : "with-divider"
              }`}
            >
              {/* <img
                className="about-subject-image"
                src={subjectsImages[index]}
                alt={subject.name}
              /> */}
              <div className="about-subject-content">{subject[lang]}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: getAppLang(state)
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(About);