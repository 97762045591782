import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAppLang, getUser } from "../redux/selectors.js";
import "./styles/BottomNavbar.css";
import { faGlobe, faHome, faRightFromBracket, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { toggleLangThunk, userLogoutThunk } from "../redux/thunks.js";

const BottomNavbar = ({
  appContainerRef,
  lang,
  toggleLang,
  openAccountModal,
  openScheduleModal,
  user,
  userLogout
}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let ticking = false;

    //console.log("BottomNavbar mounted");

    const handleScroll = () => {
      //console.log("Scrolling");
      if (!ticking) {
        requestAnimationFrame(() => {
          const aboutElement = document.getElementById("about");
          if (aboutElement) {
            setVisible(aboutElement.getBoundingClientRect().top <= 500);
          }
          ticking = false;
        });
        ticking = true;
      }
    };

    // Get the scrolling container
    const container =
      appContainerRef.current || document.querySelector(".app-container");

    container?.addEventListener("scroll", handleScroll);

    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, [appContainerRef]);

  return (
    <div className={
      `bottom-navbar ${visible
        ? "translate-y-0 opacity-100"
        : "translate-y-full opacity-0"
      }`
    }>
      <div
        className="bottom-navbar-button"
        onClick={() => {
          appContainerRef.current.scrollTo({
            top: 0, behavior: "smooth"
          });
        }}
      >
        <div className="bottom-navbar-icon enabled">
          <div className="col-button">
            <FontAwesomeIcon icon={faHome} />
            <div className="text-xs mt-1 wrap">
              {lang === "en" ? "Home" : "Accueil"}
            </div>
          </div>
        </div>
      </div>
      <div
        className="bottom-navbar-button"
        onClick={() => {
          if(user) {
            openScheduleModal();
          }
        }}
      >
        <div className={
          `bottom-navbar-icon ${user ? "enabled" : "disabled"}`
        }>
          <div className="col-button">
            <FontAwesomeIcon icon={faCalendarAlt} />
            <div className="text-xs mt-1 wrap">
              {lang === "en" ? "Schedule" : "Horaire"}
            </div>
          </div>
        </div>
      </div>
      {
        !user &&
        <div
          className="bottom-navbar-button"
          onClick={() => {
            openAccountModal();
          }}
        >
          <div className="bottom-navbar-icon enabled">
            <div className="col-button">
              <FontAwesomeIcon icon={faUser} />
              <div className="text-xs mt-1 wrap hidden md:flex">
                {lang === "en" ? "Login/Register" : "Connexion/Inscription"}
              </div>
              <div className="text-xs mt-1 wrap md:hidden">
                {lang === "en" ? "Account" : "Compte"}
              </div>
            </div>
          </div>
        </div>
      }
      {
        user &&
        <div
          className="bottom-navbar-button"
          onClick={() => {
            userLogout();
          }}
          onTouchEnd={() => {
            userLogout();
          }}
        >
          <div className="bottom-navbar-icon enabled">
            <div className="col-button">
              <FontAwesomeIcon icon={faRightFromBracket} />
              <div className="text-xs mt-1 wrap">
                {lang === "en" ? "Logout" : "Déconnexion"}
              </div>
            </div>
          </div>
        </div>
      }
      <div
        className="bottom-navbar-button"
        onClick={() => {
          const scrollPosition = appContainerRef.current.scrollTop;
          toggleLang(lang === "en" ? "fr" : "en");
          appContainerRef.current.scrollTo({
            top: lang === "en" ? scrollPosition + 84 : scrollPosition - 84,
          });
        }}
      >
        <div className="bottom-navbar-icon enabled">
          <div className="col-button">
            <div className="language-button">
              <FontAwesomeIcon icon={faGlobe} />
              <div className="text-sm ms-1">
                {lang === "en" ? "FR" : "EN"}
              </div>
            </div>
            <div className="text-xs mt-1 wrap hidden md:flex">
              {lang === "en" ? "Toggle Language" : "Changer de langue"}
            </div>
            <div className="text-xs mt-1 wrap md:hidden">
              {lang === "en" ? "Language" : "Langue"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: getAppLang(state),
  user: getUser(state)
});

const mapDispatchToProps = (dispatch) => ({
  toggleLang: (lang) => dispatch(toggleLangThunk(lang)),
  userLogout: async () => dispatch(userLogoutThunk())
});

export default connect(mapStateToProps, mapDispatchToProps)(BottomNavbar);