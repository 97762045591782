import React from 'react';
import { connect } from 'react-redux';
import { getAppLang, getUser } from "../redux/selectors.js";
import ibServiceImage from "../images/ibservice.png";
import "./styles/Service.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

const IbService = ({ lang, register, user }) => {
  return (
    <div className="service-modal">
      <div className="service-modal-title">
        {
          lang === "fr" &&
          "Préparation aux Examens du Baccalauréat International"
        }
        {
          lang === "en" &&
          "Preparation for International Baccalaureate Exams"
        }
      </div>
      <div>
        <img
          src={ibServiceImage}
          className="md:float-right m-2"
        />
        {
          lang === "fr" &&
          <div>
            À deux mois des examens du Baccalauréat International, nos services offrent un encadrement intensif pour la préparation des examens. Nous préparons les élèves à la pratique des exercices types de l'examen du Baccalauréat International en mathématiques, physique, chimie, français, anglais et biologie selon le programme cadre du Baccalauréat International.
            <br />
            <br />
            Pour cela, nous organisons des séances individualisées ou en groupe en fonction des besoins des élèves.
            <br />
            <br />
            <b>Nous avons des enseignants certifiés du Baccalauréat International qui aident :</b>
            <ul className="list-disc list-inside">
              <li>à la préparation des épreuves des examens de fin d'année du Baccalauréat International</li>
              <li>à la rédaction de l'évaluation interne.</li>
            </ul>
          </div>
        }
        {
          lang === "en" &&
          <div>
            Two months before the International Baccalaureate exams, our services offer intensive support for exam preparation. We prepare students by practicing typical exercises of the International Baccalaureate exam in mathematics, physics, chemistry, French, English, and biology according to the International Baccalaureate curriculum framework.
            <br />
            <br />
            For this, we organize individualized or group sessions according to the needs of the students.
            <br />
            <br />
            <b>We have certified International Baccalaureate teachers who help with:</b>
            <ul className="list-disc list-inside">
              <li>preparing for the end-of-year International Baccalaureate exams;</li>
              <li>writing the internal assessment.</li>
            </ul>
          </div>
        }
      </div>
      <div className="service-modal-footer">
        {
          !user &&
          <div className="register-button" onClick={register}>
            {
              lang === "fr" &&
              "Inscrivez-vous maintenant"
            }
            {
              lang === "en" &&
              "Register Now"
            }
            <FontAwesomeIcon
              className="register-icon"
              icon={faAngleRight}
            />
          </div>
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: getAppLang(state),
  user: getUser(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(IbService);