import React, { useState } from 'react';
import { connect } from 'react-redux';
import "./styles/Contact.css";
import ContactForm from "../components/ContactForm.jsx";
import ContactText from "../components/ContactText.jsx";
import { getAppLang } from "../redux/selectors.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircleNotch, faCircleXmark, faSpinner } from "@fortawesome/free-solid-svg-icons";

const Contact = ({ lang }) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  return (
    <div className="contact-section" id="contact">
      <div className="contact-info">
        <ContactText />
      </div>
      <div className="contact-form">
        {
          (success || error || loading) && (
            <div className="contact-form-dialogs">
              <div className={
                `contact-dialog ${loading ? "animate-pulse" : ""}`
              }>
                <div className={`dialog-header ${success ? "success" : error ? "error" : "loading"
                  }`}>
                  {
                    success
                      ? lang === "en"
                        ? "Success"
                        : "Succès"
                      : error
                        ? lang === "en"
                          ? "Error"
                          : "Erreur"
                        : lang === "en"
                          ? "Loading"
                          : "Chargement"
                  }
                </div>
                <div className={
                  `dialog-content ${loading ? "loading-text" : ""
                  }`
                }>
                  {
                    loading &&
                    <FontAwesomeIcon
                      icon={faSpinner}
                      className="loading-icon"
                    />
                  }
                  {
                    success
                      ? lang === "en"
                        ? "The message has been sent successfully. We will get back to you as soon as possible."
                        : "Le message a été envoyé avec succès. Nous vous répondrons dès que possible."
                      : error
                        ? lang === "en"
                          ? "There was an error sending the message. Please try again later."
                          : "Une erreur s'est produite lors de l'envoi du message. Veuillez réessayer plus tard."
                        : lang === "en"
                          ? "Please wait while we send your message."
                          : "Veuillez patienter pendant que nous envoyons votre message."
                  }
                </div>
                {
                  !loading &&
                  <div className="dialog-footer">
                    <div className="" onClick={
                      () => {
                        setSuccess(false);
                        setError(false);
                      }
                    }>
                      {
                        success &&
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="dialog-footer-icon success"
                        />
                      }
                      {
                        error &&
                        <FontAwesomeIcon
                          icon={faCircleXmark}
                          className="dialog-footer-icon error"
                        />
                      }
                    </div>
                    <div className={`dialog-footer-text ${success ? "success" : "error"}`}>
                      {
                        lang === "en"
                          ? "Close"
                          : "Fermer"
                      }
                    </div>
                  </div>
                }
              </div>
            </div>
          )
        }
        <ContactForm
          openError={() => setError(true)}
          openSuccess={() => setSuccess(true)}
          setLoading={setLoading}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: getAppLang(state)
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Contact);