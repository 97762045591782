import React from 'react';
import { connect } from 'react-redux';
import { getAppLang } from "../../redux/selectors.js";
import "./styles/Agreenment.css";
import { Checkbox, FormControlLabel } from "@mui/material";
import RegisterModalTextField from "./RegisterModalTextField.jsx";

const Agreenment = ({
  agreementData,
  errors,
  lang,
  setAgreementData,
  updateValidationErrors
}) => {
  const updateAgreementData = (key, value) => {
    setAgreementData({
      ...agreementData,
      [key]: value
    });
  };

  const toggleAgreement = () => {
    const currentAgreement = agreementData.agree;
    setAgreementData({
      agree: !currentAgreement,
      fullName: currentAgreement ? "" : agreementData.fullName,
      password: currentAgreement ? "" : agreementData.password,
      confirmPassword: currentAgreement ? "" : agreementData.confirmPassword
    });
  };

  return (
    <div className="agreement-container">
      {/* Communication */}
      <h2 className="agreement-header">{lang === "en" ? "Communication" : "Communication"}</h2>
      <div className="agreement-row">
        <ul className="agreement-list">
          <li>
            {lang === "en"
              ? "I agree to communicate with the teachers of EXCELLENT TUTORING through the digital channels of this service."
              : "Je m'engage à communiquer avec les enseignants de EXCELLENT TUTORAT dans le cadre du tutorat à travers les canaux numériques de ce service."}
          </li>
          <li>
            {lang === "en" ? (
              <span>
                I agree that I have been informed that the modifications or cancellation of sessions must be made
                <span className="font-bold ms-1">no later than the last Saturday before the tutoring week.</span>
              </span>
            ) : (
              <span>
                Je reconnais avoir été informé que les modifications ou annulation de séance doivent être faites
                <span className="font-bold ms-1">au plus tard le dernier samedi précédent la semaine de tutorat.</span>
              </span>
            )}
          </li>
        </ul>
      </div>

      {/* Hourly Rate */}
      <h2 className="agreement-header">{lang === "en" ? "Rate" : "Taux Horaire"}</h2>
      <div className="agreement-row">
        <ul className="agreement-list">
          <li>
            {lang === "en"
              ? "I agree to pay the agreed hourly rate:"
              : "Je m'engage à payer le taux horaire consenti:"}
            <span className="font-bold ms-1">{lang === "en" ? "50$/hour per session." : "50$/heure par séance."}</span>
          </li>
        </ul>
      </div>

      {/* Emergency Sessions */}
      <h2 className="agreement-header">{lang === "en" ? "Emergency Sessions" : "Séances d'Urgence"}</h2>
      <div className="agreement-row">
        <ul className="agreement-list">
          <li>
            {lang === "en" ? (
              <span>
                I acknowledge that I can request emergency sessions
                <span className="font-bold mx-1">24h before the session.</span>
                And that emergency sessions do not benefit from discounts on the hourly rate.
              </span>
            ) : (
              <span>
                Je reconnais que je peux demander des séances d'urgence
                <span className="font-bold mx-1">24h avant la séance.</span>
                Et que les séances d'urgence ne bénéficient pas de rabais sur le taux horaire.
              </span>
            )}
          </li>
        </ul>
      </div>

      {/* Confirmation Section */}
      <h2 className="agreement-header">{lang === "en" ? "Confirmation" : "Confirmation"}</h2>
      <div className="agreement-confirmation">
        {/* Checkbox */}
        <div className="agreement-checkbox">
          <FormControlLabel
            control={<Checkbox checked={agreementData.agree} onClick={toggleAgreement} />}
            label={lang === "en" ? "I Agree" : "J'accepte"}
          />
        </div>

        {/* Full Name Input */}
        <div className="agreement-input">
          <RegisterModalTextField
            label={lang === "en" ? "Full Name *" : "Nom Complet *"}
            stateFunc={(value) => updateAgreementData("fullName", value)}
            stateVal={agreementData.fullName}
            disabled={!agreementData.agree}
            error={errors.validation.fullName}
            updateError={(value) => updateValidationErrors("fullName", value)}
          />
        </div>

        {/* Password Input */}
        <div className="agreement-password">
          <RegisterModalTextField
            label={lang === "en" ? "Password *" : "Mot de Passe *"}
            stateFunc={(value) => updateAgreementData("password", value)}
            stateVal={agreementData.password}
            passwordField={true}
            disabled={!agreementData.agree}
            error={errors.validation.password}
            updateError={(value) => updateValidationErrors("password", value)}
          />
        </div>

        {/* Confirm Password Input */}
        <div className="agreement-password">
          <RegisterModalTextField
            label={lang === "en" ? "Confirm Password *" : "Confirmer le Mot de Passe *"}
            stateFunc={(value) => updateAgreementData("confirmPassword", value)}
            stateVal={agreementData.confirmPassword}
            passwordField={true}
            disabled={!agreementData.agree}
            error={errors.validation.confirmPassword}
            updateError={(value) => updateValidationErrors("confirmPassword", value)}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: getAppLang(state)
});

export default connect(mapStateToProps)(Agreenment);