import React from 'react';
import { connect } from 'react-redux';
import { getAppLang, getUser } from "../redux/selectors.js";
import englishServiceImage from "../images/anglais.png";
import frenchServiceImage from "../images/francais.png";
import "./styles/Service.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

const CourseService = ({ lang, register, user }) => {
  return (
    <div className="service-modal">
      <div className="service-modal-title">
        {
          lang === "fr" &&
          "Cours Privé de Français et d'Anglais"
        }
        {
          lang === "en" &&
          "Private French and English Courses"
        }
      </div>
      <div>
        {
          lang === "fr" &&
          <div>
            <div className="flex flex-col md:flex-row items-center mb-4">
              <img
                src={frenchServiceImage}
                className="w-[150px] md:ms-2 mb-2 md:mb-0"
              />
              <div className="">
                Selon l'Université d'Ottawa, le bilinguisme offre un avantage économique, car il constitue un atout sur le marché du travail. Au Canada, les travailleurs bilingues ont accès à un plus large éventail de postes, notamment dans la fonction publique et les services communautaires.
              </div>
            </div>
            <div className="flex flex-col md:flex-row items-center mb-4">
              <img
                src={englishServiceImage}
                className="w-[150px] md:me-2 mb-2 md:mb-0"
              />
              <div className="">
                Nous offrons des cours privés de français et d'anglais pour les personnes de tous âges. Nos enseignants sont qualifiés dans l'enseignement du Français Langue Seconde et de l'Anglais Langue Seconde.
              </div>
            </div>
          </div>
        }
        {
          lang === "en" &&
          <div>
            <div className="flex flex-col md:flex-row items-center mb-4">
              <img
                src={englishServiceImage}
                className="w-[150px] md:ms-2 mb-2 md:mb-0"
              />
              <div className="">
                According to the University of Ottawa, bilingualism offers an economic advantage as it is an asset in the job market. In Canada, bilingual workers have access to a wider range of positions, particularly in the public service and community services.
              </div>
            </div>
            <div className="flex flex-col md:flex-row items-center mb-4">
              <img
                src={frenchServiceImage}
                className="w-[150px] md:me-2 mb-2 md:mb-0"
              />
              <div className="">
                We offer private French and English courses for people of all ages. Our teachers are qualified in teaching French as a Second Language and English as a Second Language.
              </div>
            </div>
          </div>
        }
      </div>
      <div className="service-modal-footer">
        {
          !user &&
          <div className="register-button" onClick={register}>
            {
              lang === "fr" &&
              "Inscrivez-vous maintenant"
            }
            {
              lang === "en" &&
              "Register Now"
            }
            <FontAwesomeIcon
              className="register-icon"
              icon={faAngleRight}
            />
          </div>
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: getAppLang(state),
  user: getUser(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CourseService);