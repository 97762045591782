import React from 'react';
import { connect } from 'react-redux';
import { getAppLang, getUser } from "../redux/selectors.js";
import orientationServiceImage from "../images/consultation.png";
import "./styles/Service.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

const OrientationService = ({ lang, register, user }) => {
  return (
    <div className="service-modal">
      <div className="service-modal-title">
        {lang === "fr" && "Service Gratuit de Conseil en d'Orientation"}
        {lang === "en" && "Free Orientation Counseling Service"}
      </div>
      <div className="flex flex-col md:flex-row items-center">
        <img src={orientationServiceImage} className="h-[275px] mb-4 md:mb-0 md:mr-4" />
        {lang === "fr" && (
          <div className="">
            <div className="my-2">
              Nous offrons un service en orientation gratuite aux familles qui ont au moins un enfant inscrit dans un de nos programmes.
            </div>
            <div className="my-2">
              Nous accompagnons les familles dans les élèves dans leurs choix de cours au secondaire, dans leur choix d’étude collégial et universitaire.
            </div>
            <div className="my-2">
              Nous aidons les parents à comprendre les curriculums et les stratégies d’enseignement au Canada.
            </div>
            <div className="my-2">
              Les conseils de nos conseillers d’orientation et enseignant aideront les élèves dans leurs cheminement et leurs choix de carrières.
            </div>
          </div>
        )}
        {lang === "en" && (
          <div className="">
            <div className="my-2">
              We offer a free orientation service to families who have at least one child enrolled in one of our programs.
            </div>
            <div className="my-2">
              We assist families and students in their choice of high school courses, and in their college and university studies.
            </div>
            <div className="my-2">
              We help parents understand the curriculums and teaching strategies in Canada.
            </div>
            <div className="my-2">
              The advice from our orientation counselors and teachers will help students in their journey and career choices.
            </div>
          </div>
        )}
      </div>
      <div className="service-modal-footer">
        {
          !user &&
          <div className="register-button" onClick={register}>
            {
              lang === "fr" &&
              "Inscrivez-vous maintenant"
            }
            {
              lang === "en" &&
              "Register Now"
            }
            <FontAwesomeIcon
              className="register-icon"
              icon={faAngleRight}
            />
          </div>
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: getAppLang(state),
  user: getUser(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(OrientationService);