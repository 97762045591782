import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import "./styles/NewSessionModal.css";
import { getAllStudents, getAppLang, getPeople, getUserData } from "../redux/selectors.js";
import logo from "../images/logo.png";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import RegisterModalTextField from "../components/register/RegisterModalTextField.jsx";
import { v4 as uuid } from "uuid";
import { sessionAddThunk } from "../redux/thunks.js";

const NewSessionModal = ({
  closeModal,
  lang,
  modalRef,
  sessionAdd,
  students,
  people,
  user
}) => {
  const [student, setStudent] = useState("");
  const [course, setCourse] = useState("");
  const [sessionDate, setSessionDate] = useState(null);
  const [sessionTime, setSessionTime] = useState(null);
  const [sessionDuration, setSessionDuration] = useState(60); // Default 60 minutes
  const [sessionCost, setSessionCost] = useState("50"); // NEW: Cost Input
  const [location, setLocation] = useState("Online");

  const [errors, setErrors] = useState({
    student: "",
    course: "",
    sessionDate: "",
    sessionTime: "",
    sessionDuration: "",
    sessionCost: "",
    location: "",
  });

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  const clearFields = () => {
    setStudent("");
    setCourse("");
    setSessionDate(null);
    setSessionTime(null);
    setSessionDuration(60);
    setSessionCost("50");
    setLocation("Online");
  };

  const validateFields = () => {
    let newErrors = {};

    if (!student) {
      newErrors.student = lang === "en" ? "Please select a student." : "Veuillez sélectionner un élève.";
    }
    if (!course) {
      newErrors.course = lang === "en" ? "Please select a course." : "Veuillez sélectionner un cours.";
    }
    if (!sessionDate) {
      newErrors.sessionDate = lang === "en" ? "Please select a date." : "Veuillez sélectionner une date.";
    }
    if (!sessionTime) {
      newErrors.sessionTime = lang === "en" ? "Please select a time." : "Veuillez sélectionner une heure.";
    }
    if (!sessionDuration || sessionDuration < 30 || sessionDuration > 180) {
      newErrors.sessionDuration = lang === "en" ? "Please select a valid duration (30 to 180 minutes)." : "Veuillez sélectionner une durée valide (30 à 180 minutes).";
    }
    if (!sessionCost || sessionCost <= 0) {
      newErrors.sessionCost = lang === "en" ? "Please enter a valid cost." : "Veuillez saisir un coût valide.";
    }
    if (!location.trim()) {
      newErrors.location = lang === "en" ? "Please enter a location." : "Veuillez saisir un lieu.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns `true` if no errors exist
  };

  const handleCreateSession = (event) => {
    event.preventDefault();

    if (!validateFields()) {
      //console.log("Invalid fields");
      return;
    }

    const sessionId = uuid();

    const newSession = {
      id: sessionId,
      student: student,
      studentName: people.find((person) => person.id === student)?.firstName,
      teacherName: `${user?.firstName} ${user?.lastName}`,
      teacher: user?.id,
      course: course,
      session: {
        date: sessionDate,
        time: sessionTime,
        location: location,
        duration: sessionDuration,
        cost: parseInt(sessionCost), // NEW: Added cost field
        emergencySession: false,
        status: "scheduled",
        confirmedByStudent: false,
        confirmedByTeacher: true,
      }
    };

    //console.log(newSession);

    sessionAdd(newSession);
    clearFields();
    closeModal();
  };


  return (
    <div className="new-session-modal-backdrop" onClick={handleBackdropClick} ref={modalRef}>
      <div className="new-session-modal">
        <div className="new-session-modal-logo">
          <img className="modal-logo" src={logo} alt="Logo" />
        </div>
        <div className="new-session-modal-header">
          {lang === "en" ? "New Session" : "Nouvelle Session"}
        </div>

        {/* Wrap the entire modal body inside LocalizationProvider */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {/* Modal Body */}
          <div className="new-session-modal-body">
            {/* Select Student */}
            <FormControl fullWidth variant="outlined" className="modal-input" error={!!errors.student}>
              <InputLabel>{lang === "en" ? "Select Student" : "Sélectionner un élève"}</InputLabel>
              <Select value={student} onChange={(e) => setStudent(e.target.value)} label={lang === "en" ? "Select Student" : "Sélectionner un élève"}>
                {students?.map((student) => (
                  <MenuItem key={student.id} value={student.id}>
                    {student.firstName} {student.lastName}
                  </MenuItem>
                ))}
              </Select>
              {errors.student && <p className="error-text">{errors.student}</p>}
            </FormControl>

            {/* Select Course */}
            <FormControl fullWidth variant="outlined" className="modal-input" error={!!errors.course}>
              <InputLabel>{lang === "en" ? "Select Course" : "Sélectionner un cours"}</InputLabel>
              <Select value={course} onChange={(e) => setCourse(e.target.value)} label={lang === "en" ? "Select Course" : "Sélectionner un cours"}>
                <MenuItem value="math">{lang === "en" ? "Math" : "Mathématiques"}</MenuItem>
                <MenuItem value="science">{lang === "en" ? "Science" : "Sciences"}</MenuItem>
                <MenuItem value="english">{lang === "en" ? "English" : "Anglais"}</MenuItem>
                <MenuItem value="physicalScience">{lang === "en" ? "Physical Sciences" : "Sciences Physiques"}</MenuItem>
                <MenuItem value="biology">{lang === "en" ? "Biology" : "Biologie"}</MenuItem>
                <MenuItem value="french">{lang === "en" ? "French" : "Français"}</MenuItem>
                <MenuItem value="other">{lang === "en" ? "Other" : "Autre"}</MenuItem>
              </Select>
              {errors.course && <p className="error-text">{errors.course}</p>}
            </FormControl>

            {/* Select Date */}
            <div className="time-duration-container">
              <TextField
                label={lang === "en" ? "Select Date" : "Sélectionner une date"}
                type="date"
                value={sessionDate || ""}
                onChange={(e) => setSessionDate(e.target.value)}
                error={!!errors.sessionDate}
                helperText={errors.sessionDate}
                fullWidth
                className="modal-input"
                InputLabelProps={{ shrink: true }}
              />

              <TextField
                label={lang === "en" ? "Select Time" : "Sélectionner une heure"}
                type="time"
                value={sessionTime || ""}
                onChange={(e) => setSessionTime(e.target.value)}
                error={!!errors.sessionTime}
                helperText={errors.sessionTime}
                fullWidth
                className="modal-input"
                InputLabelProps={{ shrink: true }}
              />
            </div>

            {/* Duration & Cost Container */}
            <div className="time-duration-container">
              <FormControl fullWidth variant="outlined" className="modal-input" error={!!errors.sessionDuration}>
                <InputLabel>{lang === "en" ? "Select Duration" : "Sélectionner la durée"}</InputLabel>
                <Select value={sessionDuration} onChange={(e) => setSessionDuration(e.target.value)}>
                  {[30, 60, 90, 120, 150, 180].map((minutes) => (
                    <MenuItem key={minutes} value={minutes}>
                      {minutes} {lang === "en" ? "minutes" : "minutes"}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                label={lang === "en" ? "Cost ($)" : "Coût ($)"}
                type="number"
                value={sessionCost}
                onChange={(e) => setSessionCost(e.target.value)}
                error={!!errors.sessionCost}
                helperText={errors.sessionCost}
                fullWidth
                className="modal-input"
                inputProps={{ min: 0 }}
              />
            </div>

            {/* Location Input */}
            <RegisterModalTextField
              label={lang === "en" ? "Location" : "Lieu"}
              stateVal={location}
              stateFunc={setLocation}
              error={!!errors.location} // Pass the error state
              helperText={errors.location} // Show helper text if error exists
            />
          </div>
        </LocalizationProvider>

        {/* Modal Footer */}
        <div className="new-session-modal-footer">
          <div
            className="new-session-modal-button"
            onClick={(event) => handleCreateSession(event)}
            onTouchEnd={(event) => handleCreateSession(event)}
          >
            {lang === "fr" ? "Créer une session" : "Create Session"}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: getAppLang(state),
  students: getAllStudents(state),
  people: getPeople(state),
  user: getUserData(state),
});

const mapDispatchToProps = (dispatch) => ({
  sessionAdd: (session) => dispatch(sessionAddThunk(session)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewSessionModal);