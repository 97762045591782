import { TextField } from "@mui/material";
import { withStyles } from "@mui/styles";
import React from 'react';
import { connect } from 'react-redux';

const CssTextField = withStyles({
  root: {
    "& label": {
      color: "#334155",
      "&.Mui-focused": {
        color: "#0f172a"
      }
    },
    "&:hover label:not(.Mui-disabled)": {
      color: "#0f172a"
    },
    "&:hover .MuiOutlinedInput-input:not(.Mui-disabled)": {
      color: "#0f172a"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0f172a"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#334155",
      },
      "&:hover fieldset:not(.Mui-disabled)": {
        borderColor: "#0f172a"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0f172a"
      }
    },
    "& .MuiOutlinedInput-input": {
      color: "#334155"
    }
  }
})(TextField);

const RegisterModalTextField = ({
  disabled=false,
  error="",
  label,
  passwordField,
  phoneField=false,
  reference=undefined,
  stateVal,
  stateFunc,
  updateError
}) => {
  const handleChange = (e) => {
    let value = e.target.value;
    if (phoneField) {
      value = value.replace(/\D/g, ''); // Remove non-numeric characters
      if (value.length > 10) {
        value = value.slice(0, 10); // Restrict to 10 characters
      }
    }
    stateFunc(value);
  };

  const handleKeyPress = (e) => {
    if (phoneField && !/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <CssTextField
      label={label}
      variant="standard"
      value={stateVal}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      type={passwordField ? "password" : "text"}
      ref={reference}
      disabled={disabled}
      fullWidth
      error={Boolean(error)} // Ensures MUI correctly sets the error state
      helperText={error}
      onBlur={() => {
        if (updateError) {
          updateError("");
        }
      }}
      inputProps={{
        inputMode: phoneField ? "numeric" : "text",
        pattern: phoneField ? "[0-9]*" : undefined,
        maxLength: phoneField ? 10 : undefined
      }}
    />
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterModalTextField);