import React from 'react';
import { connect } from 'react-redux';
import { getAppLang } from "../../redux/selectors.js";
import RegisterModalTextField from "./RegisterModalTextField.jsx";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import "./styles/MobileChildInfoRow.css";

const MobileChildInfoRow = ({
  childErrors,
  data,
  deleteChild,
  lang,
  rowIndex,
  updateChildData,
  updateChildErrors
}) => {
  return (
    <div className="mobile-child-info-container">
      {/* Child Number & Remove Button */}
      <div className="mobile-child-header">
        <span className="mobile-child-index">{rowIndex + 1}.</span>
        <button className="remove-mobile-child-button" onClick={deleteChild}>
          <FontAwesomeIcon icon={faX} />
        </button>
      </div>

      {/* Input Fields: Responsive Grid */}
      <div className="mobile-child-fields">
        <RegisterModalTextField
          label={lang === "en" ? "First Name *" : "Prénom *"}
          stateFunc={(value) => updateChildData("firstName", value)}
          stateVal={data.firstName}
          error={childErrors.firstName}
          updateError={(value) => updateChildErrors("firstName", value)}
        />
        <RegisterModalTextField
          label={lang === "en" ? "Middle Name (Optional)" : "Deuxième prénom (facultatif)"}
          stateFunc={(value) => updateChildData("middleName", value)}
          stateVal={data.middleName}
        />
        <RegisterModalTextField
          label={lang === "en" ? "Last Name *" : "Nom *"}
          stateFunc={(value) => updateChildData("lastName", value)}
          stateVal={data.lastName}
          error={childErrors.lastName}
          updateError={(value) => updateChildErrors("lastName", value)}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            className="mobile-date-picker"
            label={lang === "en" ? "Date of Birth *" : "Date de naissance *"}
            slotProps={{
              textField: {
                variant: "standard",
                InputLabelProps: {
                  classes: {
                    focused: "mobile-date-picker-label-focused",
                    error: "mobile-date-picker-label-error"
                  }
                },
                InputProps: {
                  classes: {
                    underline: "mobile-date-picker-underline",
                    error: "mobile-date-picker-error"
                  }
                },
                onBlur: () => updateChildErrors("dateOfBirth", ""),
                error:
                  childErrors.dateOfBirth !== "" &&
                  childErrors.dateOfBirth !== null &&
                  childErrors.dateOfBirth !== undefined,
                helperText: childErrors.dateOfBirth
              }
            }}
            value={data.dateOfBirth}
            onChange={(date) => updateChildData("dateOfBirth", date)}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: getAppLang(state)
});

export default connect(mapStateToProps)(MobileChildInfoRow);