import React from 'react';
import { connect } from 'react-redux';
import { getAppLang, getUser } from "../redux/selectors.js";
import supportServiceImage from "../images/soutient.png";
import "./styles/Service.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

const SupportService = ({ lang, register, user }) => {
  return (
    <div className="service-modal">
      <div className="service-modal-title">
        {lang === "fr" && "Soutiens Scolaires Individuels"}
        {lang === "en" && "Individual Academic Support"}
      </div>
      <div>
        <img src={supportServiceImage} className="float-right w-[300px] m-2" />
        {lang === "fr" && (
          <div>
            <div className="mb-2">
              Nos enseignantes et enseignants donnent un soutien scolaire
              individualisé aux élèves du cycle élémentaire et du cycle
              secondaire en fonction des besoins de l’élève. Cet accompagnement
              permet aux enseignantes et enseignants d’aider l’élève:
            </div>
            <ul className="list-disc list-inside">
              <li>à faire face à ces défis de l’élèves;</li>
              <li>à préparer ses évaluations;</li>
              <li>à faire ses devoirs par des explications des contenus;</li>
              <li>préparer les examens de fin de semestre et de fin d’année;</li>
            </ul>
            <br />
            <span className="font-semibold">Nous offrons:</span>
            <ul className="list-disc list-inside">
              <li>des séances en lignes d’une heure par séance;</li>
              <li>
                des séances d’urgence en ligne de 15 à 20 minutes pour répondre
                aux questions de nos élèves;
              </li>
              <li>
                des séances en présentiel dans l’environnement familial ou dans
                la bibliothèque la plus proche de l’élève;
              </li>
              <li>des horaires flexibles;</li>
              <li>
                des ressources aux élèves pour permettre la continuité
                d’apprentissage de façon autonome.
              </li>
            </ul>
            <br />
            <span className="font-semibold">Nos enseignantes et enseignants sont:</span>
            <ul className="list-disc list-inside">
              <li>
                des membres de l’ordre des enseignantes et enseignants de
                l’Ontario;
              </li>
              <li>
                des enseignantes et enseignants brevet du ministère de
                l’éducation du Québec;
              </li>
              <li>
                des enseignantes et enseignants qualifiés en Français Langue
                Seconde;
              </li>
              <li>
                des enseignantes et enseignants qualifiés en Anglais Langue
                Seconde.
              </li>
            </ul>
          </div>
        )}
        {lang === "en" && (
          <div>
            <div className="mb-2">
              Our teachers provide individualized academic support to elementary and secondary school students based on their needs. This support allows teachers to help students:
            </div>
            <ul className="list-disc list-inside">
              <li>to face their challenges;</li>
              <li>to prepare for their assessments;</li>
              <li>to do their homework by explaining the content;</li>
              <li>to prepare for end-of-semester and end-of-year exams;</li>
            </ul>
            <br />
            <span className="font-semibold">We offer:</span>
            <ul className="list-disc list-inside">
              <li>online sessions of one hour per session;</li>
              <li>
                emergency online sessions of 15 to 20 minutes to answer our students' questions;
              </li>
              <li>
                in-person sessions in the family environment or in the nearest library to the student;
              </li>
              <li>flexible schedules;</li>
              <li>
                resources for students to enable continuous learning independently.
              </li>
            </ul>
            <br />
            <span className="font-semibold">Our teachers are:</span>
            <ul className="list-disc list-inside">
              <li>
                members of the Ontario College of Teachers;
              </li>
              <li>
                certified teachers from the Quebec Ministry of Education;
              </li>
              <li>
                qualified in French as a Second Language;
              </li>
              <li>
                qualified in English as a Second Language.
              </li>
            </ul>
          </div>
        )}
      </div>
      <div className="service-modal-footer">
        {
          !user &&
          <div className="register-button" onClick={register}>
            {
              lang === "fr" &&
              "Inscrivez-vous maintenant"
            }
            {
              lang === "en" &&
              "Register Now"
            }
            <FontAwesomeIcon
              className="register-icon"
              icon={faAngleRight}
            />
          </div>
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: getAppLang(state),
  user: getUser(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SupportService);