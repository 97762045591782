import React from 'react';
import { connect } from 'react-redux';
import { getAppLang, getUser, getUserData } from "../redux/selectors.js";
import { GlobeAltIcon } from "@heroicons/react/24/solid";
import { toggleLangThunk, userLogoutThunk } from "../redux/thunks.js";
import "./styles/Navbar.css";

import appData from "../data/appData.json";
import logo from "../images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";

const scrollToSection = (event, sectionId) => {
  event.preventDefault();
  const section = document.getElementById(sectionId);
  if (section) {
    const navbarHeight = document.querySelector(".navbar")?.offsetHeight || 50; // Adjust dynamically
    const offsetPosition = section.offsetTop - navbarHeight;

    document.querySelector(".app-container").scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
};

const Navbar = ({
  lang,
  openAdminModal,
  openLoginModal,
  openRegisterModal,
  openScheduleModal,
  toggleLang,
  user,
  userData,
  userLogout
}) => {

  const navLinks = appData?.navbarLinks?.map((link, index) => (
    <div
      key={index}
      className="navbar-link"
      onClick={(event) => scrollToSection(event, link?.section)}
    >{link?.title[lang]}</div>
  ));

  const userButtons = appData?.navbarUser?.map((button, index) => (
    <div
      key={index}
      className={`navbar-button ${button?.class}`}
      onClick={() => {
        if (button?.action === "login") {
          openLoginModal();
        } else if (button?.action === "register") {
          openRegisterModal();
        }
      }}
    >{button?.title[lang]}</div>
  ));

  const currentUserButtons = appData?.navbarCurrentUser
    ?.map((button, index) => (
      <div
        key={index}
        className={`navbar-button ${button?.class[lang]}`}
        onClick={() => {
          if (button?.action === "schedule") {
            openScheduleModal();
          } else if (button?.action === "logout") {
            userLogout();
          }
        }}
      >
        <FontAwesomeIcon
          icon={(() => {
            if (button?.action === "schedule") {
              return faCalendar;
            } else if (button?.action === "logout") {
              return faRightFromBracket;
            }
          })()}
          className="navbar-curent-icon"
        />
        {button?.title[lang]}
      </div>
    ));

  const toggleLangHandler = () => {
    if (lang === "en") {
      toggleLang("fr");
    } else {
      toggleLang("en");
    }
  };

  return (
    <div className="navbar">
      <div className="navbar-logo navbar-section">
        <img className="logo" src={logo} alt="Logo" />
      </div>
      <div className="navbar-links navbar-section">
        {navLinks}
      </div>
      <div className="navbar-user navbar-section">
        {!user && userButtons}
        {/* {
          user && userData?.role === "admin" &&
          <div
            className={`navbar-button ${lang === "en" ? "navbar-admin-en" : "navbar-admin"
              }`}
            onClick={() => openAdminModal()}
          >
            <FontAwesomeIcon icon={faGear} className="navbar-curent-icon" />
            {
              lang === "en" ?
                "Admin" :
                "Administration"
            }
          </div>
        } */}
        {user && currentUserButtons}
      </div>
      <div
        className="navbar-section group/lang navbar-lang-section"
        onClick={() => toggleLangHandler()}
      >
        <div className="navbar-lang">{lang.toUpperCase()}</div>
        <GlobeAltIcon className="navbar-globe" />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: getAppLang(state),
  user: getUser(state),
  userData: getUserData(state),
});

const mapDispatchToProps = (dispatch) => ({
  toggleLang: (lang) => dispatch(toggleLangThunk(lang)),
  userLogout: async () => dispatch(userLogoutThunk())
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);