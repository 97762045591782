import React from 'react';
import { connect } from 'react-redux';
import { getAppLang } from "../redux/selectors.js";
import "./styles/Services.css";

import data from "../data/appData.json";
import { faAngleRight, faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const servicesData = data?.services;

const serviceIcons = [
  require("../images/ib.png"),
  require("../images/solo-dark.png"),
  require("../images/bilingual.png"),
  require("../images/orientation-dark.png"),
];

const Services = ({ lang, openModal }) => {
  return (
    <div className="services" id="services">
      <div className="services-background"></div>
      <div className="services-header">{servicesData?.header[lang]}</div>
      <div className="services-content">
        {servicesData?.contentList?.map((service, index) => (
          <div
            key={index}
            className="service bg-contain bg-center bg-no-repeat"
            style={{
              backgroundImage: `url(${serviceIcons[index]})`,
              backgroundSize: '75% auto'
            }}
          >
            <div className="service-title">{service.title[lang]}</div>
            <div className="service-button-wrapper" onClick={() => openModal(service?.modalKey)}>
              <div className="text-sm md:text-xs lg:text-sm">
                {
                  lang === "fr" &&
                  "En savoir plus"
                }
                {
                  lang === "en" &&
                  "Learn More"
                }
              </div>
              <div className="service-button">
                <FontAwesomeIcon className="service-more" icon={faAngleRight} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: getAppLang(state)
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Services);