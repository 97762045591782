import { TextField } from "@mui/material";
import { withStyles } from "@mui/styles";
import React from 'react';

const CssTextField = withStyles({
  root: {
    "& label": {
      color: "#cbd5e1",
      "&.Mui-focused": {
        color: "white"
      }
    },
    "&:hover label": {
      color: "white"
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "white"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#cbd5e1",
      },
      "&:hover fieldset": {
        borderColor: "white"
      },
      "&.Mui-focused fieldset": {
        borderColor: "white"
      }
    },
    "& .MuiOutlinedInput-input": {
      color: "#cbd5e1"
    }
  }
})(TextField);

const ContactFormField = ({ label, stateVal, stateFunc, error, updateError }) => {
  const handleChange = (e) => {
    stateFunc(e.target.value);

    // Clear error when the user types
    if (error) {
      updateError();
    }
  };

  return (
    <CssTextField
      label={label}
      variant="outlined"
      value={stateVal}
      onChange={handleChange}
      fullWidth
      error={Boolean(error)} // Ensures MUI correctly sets the error state
      helperText={error}
    />
  );
};

export default ContactFormField;