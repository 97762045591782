import React from 'react';
import { connect } from 'react-redux';
import "./styles/Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faSquareFacebook } from "@fortawesome/free-brands-svg-icons/faSquareFacebook";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faCopyright } from "@fortawesome/free-regular-svg-icons/faCopyright";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";

const Footer = (props) => {
  return (
    <>
      <div className="footer">
        <div className="footer-section">
          <span className="text-2xl me-2">
            <FontAwesomeIcon icon={faCopyright} />
          </span>
          2025 Excellent Tutorat
        </div>
        <div className="footer-section">
          <a
            href="https://www.instagram.com/excellent_tutorat?igsh=MWRvbXJndTJ2aTdm"
            target="_blank"
            className="footer-link"
          >
            <FontAwesomeIcon icon={faInstagram} className="text-2xl" />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=100087627475355&mibextid=9R9pXO"
            target="_blank"
            className="footer-link"
          >
            <FontAwesomeIcon icon={faSquareFacebook} className="text-2xl" />
          </a>
          <span className="ms-1">@excellent_tutorat</span>
        </div>
        <div className="footer-section footer-numbers">
          <a href="tel:+16473553893" className="footer-link footer-number">
            <FontAwesomeIcon icon={faPhone} className="text-2xl" />
            <span className="ms-1">(647) 355-3893</span>
          </a>
          <a href="tel:+15148910412" className="footer-link footer-number">
            <FontAwesomeIcon icon={faPhone} className="text-2xl" />
            <span className="ms-1">(514) 891-0412</span>
          </a>
        </div>
        <div className="footer-section">
          <a href="mailto:info@excellenttutorat.ca" className="footer-link footer-number">
            <FontAwesomeIcon icon={faEnvelope} className="text-2xl" />
            <span className="ms-1">info@excellenttutorat.ca</span>
          </a>
        </div>
      </div>
      <div className="footer-responsive">
        <div className="footer-responsive-section spread mt-4 sm:mb-4">
          <a href="mailto:info@excellenttutorat.ca" className="footer-responsive-link">
            <FontAwesomeIcon icon={faEnvelope} className="text-5xl" />
          </a>
          <a
            href="https://www.instagram.com/excellent_tutorat?igsh=MWRvbXJndTJ2aTdm"
            target="_blank"
            className="footer-responsive-link"
          >
            <FontAwesomeIcon icon={faInstagram} className="text-5xl" />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=100087627475355&mibextid=9R9pXO"
            target="_blank"
            className="footer-responsive-link"
          >
            <FontAwesomeIcon icon={faSquareFacebook} className="text-5xl" />
          </a>
          <div className="footer-responsive-numbers">
            <a href="tel:+16473553893" className="footer-link footer-responsive-number">
              <FontAwesomeIcon icon={faPhone} className="text-3xl py-1 sm:py-0" />
              <span className="ms-1">(647) 355-3893</span>
            </a>
            <a href="tel:+15148910412" className="footer-link footer-responsive-number">
              <FontAwesomeIcon icon={faPhone} className="text-3xl py-1 sm:py-0" />
              <span className="ms-1">(514) 891-0412</span>
            </a>
          </div>
        </div>
        <div className="footer-responsive-section justify-center">
          <span className="text-2xl me-2">
            <FontAwesomeIcon icon={faCopyright} />
          </span>
          2025 Excellent Tutorat
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);