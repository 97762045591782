import React from 'react';
import { connect } from 'react-redux';
import { getAppLang } from "../redux/selectors.js";
import appData from "../data/appData.json";
import "./styles/ContactText.css";
const ContactText = ({ lang }) => {
  return (
    <div className="contact-text">
      <div className="contact-text-header">
        {appData?.contact?.text?.header[lang]}
      </div>
      <div className="contact-text-content">
        {appData?.contact?.text?.content[lang]}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: getAppLang(state)
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ContactText);