import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import "./styles/RegisterModal.css";
import { getAppLang } from "../redux/selectors.js";
import logo from "../images/logo.png";
import RegisterStepper from "../components/register/RegisterStepper.jsx";

const RegisterModal = ({ closeModal, lang, modalRef }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      //console.log("clicked");
      closeModal();
    }
  };

  return (
    <div
      className="register-modal-backdrop"
      onClick={handleBackdropClick}
      ref={modalRef}
    >
      <div className="register-modal">
        <div className="register-modal-logo">
          <img className="modal-logo" src={logo} alt="Logo" />
        </div>
        <div className="register-modal-header">
          {lang === "en" ? "Register" : "Inscription"}
        </div>
        <div className="register-modal-body">
          <RegisterStepper closeModal={closeModal} />
        </div>
        <div className="register-modal-footer"></div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: getAppLang(state)
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterModal);