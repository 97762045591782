import { connect } from "react-redux";
import './App.css';
import { loadDataThunk } from "./redux/thunks.js";
import { getUser } from "./redux/selectors.js";
import Navbar from "./components/Navbar.jsx";
import Footer from "./components/Footer.jsx";
import Contact from "./sections/Contact.jsx";
import Landing from "./sections/Landing.jsx";
import About from "./sections/About.jsx";
import Servicees from "./sections/Servicees.jsx";
import { useEffect, useRef, useState } from "react";
import AdminModal from "./modals/AdminModal.jsx";
import LoginModal from "./modals/LoginModal.jsx";
import RegisterModal from "./modals/RegisterModal.jsx";
import ScheduleModal from "./modals/ScheduleModal.jsx";
import ServiceModal from "./modals/ServiceModal.jsx";
import BottomNavbar from "./components/BottomNavbar.jsx";
import AccountModal from "./modals/AccountModal.jsx";
import { auth } from "./firebase.js";
import { onAuthStateChanged } from "firebase/auth";

const App = ({
  loadData,
  user,
}) => {
  const screenWidth = window.innerWidth;

  const appContainerRef = useRef(null);

  const [modals, setModals] = useState({
    accountModal: { status: false, ref: useRef() },
    adminModal: { status: false, ref: useRef() },
    loginModal: { status: false, ref: useRef() },
    registerModal: { status: false, ref: useRef() },
    scheduleModal: { status: false, ref: useRef() },
    serviceModal: { status: false, ref: useRef() },
  });

  const [activeService, setActiveService] = useState(null);

  const closeModal = (modal) => {
    setModals({
      ...modals,
      [modal]: { status: false, ref: modals[modal].ref }
    });
  };

  const openModal = (modal) => {
    setModals({
      ...modals,
      [modal]: { status: true, ref: modals[modal].ref }
    });
  };

  const login = (modalToClose = "") => {
    if (modalToClose !== "") {
      setTimeout(() => {
        setModals({
          ...modals,
          loginModal: { status: true, ref: modals.loginModal.ref },
          [modalToClose]: { status: false, ref: modals[modalToClose].ref }
        });
      }, 50);
    } else {
      setModals({
        ...modals,
        loginModal: { status: true, ref: modals.loginModal.ref }
      });
    }
  };

  const register = (modalToClose = "") => {
    if (modalToClose !== "") {
      setTimeout(() => {
        setModals({
          ...modals,
          registerModal: { status: true, ref: modals.registerModal.ref },
          [modalToClose]: { status: false, ref: modals[modalToClose].ref }
        });
      }, 50);
    } else {
      setModals({
        ...modals,
        registerModal: { status: true, ref: modals.registerModal.ref }
      });
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        loadData(firebaseUser.email);
      } else if (user) {
        loadData(user?.email);
      }
    });
  
    return () => unsubscribe(); // Cleanup listener on unmount
  }, [user, loadData]);

  return (
    <div className="App bg-slate-900 text-white">
      <div className="app-container" ref={appContainerRef}>
        {screenWidth > 1024 &&
          <>
            <Navbar
              openAdminModal={() => openModal("adminModal")}
              openLoginModal={() => openModal("loginModal")}
              openRegisterModal={() => openModal("registerModal")}
              openScheduleModal={() => openModal("scheduleModal")}
            />
          </>
        }
        {
          screenWidth <= 1024 &&
          <>
            <BottomNavbar
              appContainerRef={appContainerRef}
              openAccountModal={() => openModal("accountModal")}
              openScheduleModal={() => openModal("scheduleModal")}
            />
            {
              modals?.accountModal?.status &&
              <AccountModal
                closeModal={() => closeModal("accountModal")}
                modalRef={modals?.accountModal?.ref}
                login={() => login("accountModal")}
                register={() => register("accountModal")}
              />
            }
          </>
        }
        <div style={{
          display: modals?.serviceModal?.status ? "block" : "none"
        }}>
          <ServiceModal
            activeService={activeService}
            closeModal={() => closeModal("serviceModal")}
            modalRef={modals?.serviceModal?.ref}
            register={() => register("serviceModal")}
          />
        </div>
        <div style={{
          display: modals?.loginModal?.status ? "block" : "none"
        }}>
          <LoginModal
            closeModal={() => closeModal("loginModal")}
            modalRef={modals?.loginModal?.ref}
          />
        </div>
        <div style={{
          display: modals?.registerModal?.status ? "block" : "none"
        }}>
          <RegisterModal
            closeModal={() => closeModal("registerModal")}
            modalRef={modals?.registerModal?.ref}
          />
        </div>
        <div style={{
          display: modals?.scheduleModal?.status ? "block" : "none"
        }}>
          <ScheduleModal
            closeModal={() => closeModal("scheduleModal")}
            modalRef={modals?.scheduleModal?.ref}
          />
        </div>
        <div style={{
          display: modals?.adminModal?.status ? "block" : "none"
        }}>
          <AdminModal
            closeModal={() => closeModal("adminModal")}
            modalRef={modals?.adminModal?.ref}
          />
        </div>
        <Landing />
        <About />
        <Servicees openModal={(service) => {
          setActiveService(service);
          setTimeout(() => {
            openModal("serviceModal");
          }, 100);
        }} />
        <Contact />
        <Footer />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state)
});

const mapDispatchToProps = (dispatch) => ({
  loadData: (user) => dispatch(loadDataThunk(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);